import React from 'react';
import { useTranslation } from 'react-i18next';
import { Frame, SageClassnames, useSageBreakpoint, Divider, Badge } from '@kajabi/sage-react';
import CheckoutActions from './CheckoutActions';
import { Cart } from './types';
import CheckoutCartItems from './CheckoutCartItems';
import { localizedPriceFromMinorUnit } from '../popup_checkout/util';
import { useCart } from './CartContext';
import PayButton from './PayButton';

interface CheckoutCartSummaryProps {
  cart: Cart;
  goBackToCart: () => void;
}

const CheckoutCartSummary = ({ cart }: CheckoutCartSummaryProps) => {
  const { cartCount } = useCart();
  const breakpoints = useSageBreakpoint();
  const { t } = useTranslation();

  const { totalPrice } = cart;
  const localizedTotalPrice = totalPrice
    ? localizedPriceFromMinorUnit(totalPrice.currency, totalPrice.amount, totalPrice.exponent)
    : null;
  return (
    <>
      {breakpoints.sm && <Divider />}
      <Frame
        aria-label="cart checkout summary"
        direction={Frame.DIRECTIONS.VERTICAL}
        gap={Frame.GAPS.XS}
        className="checkout-cart-summary"
        align={Frame.ALIGNMENTS.CENTER_SPREAD}
        tag="section"
        aria-labelledby="order-summary-heading"
      >
        <div className="checkout-cart-summary-content">
          {breakpoints.sm && (
            <h5 id="order-summary-heading" className={`${SageClassnames.TYPE.HEADING_6}`}>
              {t('messages.cart.order_summary')} ({cartCount})
            </h5>
          )}
          <CheckoutCartItems cartItems={cart.items} />
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            gap={Frame.GAPS.XS}
            align={Frame.ALIGNMENTS.CENTER_SPREAD}
            width={Frame.WIDTHS.FILL}
            className={`${SageClassnames.SPACERS.XS_TOP} ${SageClassnames.TYPE.HEADING_6}`}
          >
            <span className="cart-subtotal" id="cart-subtotal">
              {t('form.subtotal')}
            </span>
            <span>
              {' '}
              <span
                className="cart-subtotal"
                aria-live="polite"
                aria-labelledby="cart-subtotal"
                data-testid="order-subtotal"
              >
                {localizedTotalPrice}
              </span>
            </span>
          </Frame>
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            gap={Frame.GAPS.XS}
            align={Frame.ALIGNMENTS.CENTER_SPREAD}
            width={Frame.WIDTHS.FILL}
          >
            <h5 id="order-total" className={`${SageClassnames.TYPE.HEADING_6}`}>
              {t('messages.cart.order_total')}
            </h5>
            <h5
              data-testid="order-total"
              className={`${SageClassnames.TYPE.HEADING_6}`}
              aria-labelledby="order-total"
            >
              <Badge
                className={`${SageClassnames.SPACERS.XS_RIGHT}`}
                value={cart.totalPrice?.currency}
              />
              {localizedTotalPrice}
            </h5>
          </Frame>
          {breakpoints.sm && <CheckoutActions />}
          <PayButton />
        </div>
      </Frame>
    </>
  );
};

export default CheckoutCartSummary;
