import { useTranslation } from 'react-i18next';

const API_ROOT = '/api';

export const PATHS = {
  ROOT: API_ROOT,
  API: {
    ROOT: API_ROOT,
    CART: `${API_ROOT}/cart`,
    ADD_ITEM: `${API_ROOT}/cart/add_item`,
    UPDATE_ITEM: `${API_ROOT}/cart/update_item`,
    ORDER_CHECKOUT: `${API_ROOT}/order_checkout`,
  },
};
