import { Context } from 'apps/commerce/cart/types';
import React from 'react';

export type ModalProps = {
  component: React.ElementType | null;
  props?: {};
};

// This is the shape of the context object that will be provided by the CartProvider.
// It includes methods and state variables that can be used by any child component of the CartProvider.
// The CartProvider will provide this context object to any component that uses the useCart hook.
export interface CartContextObject {
  // eslint-disable-next-line no-unused-vars
  showModal: (component: React.ElementType, props?: any) => void;
  hideModal: () => void;
  context: Context;
  fetchCart: () => void;
  // eslint-disable-next-line no-unused-vars
  addItemToCart: (itemId: string) => void;
  updateCartItem: (cartItemId: string, quantity: number) => void;
  modal: ModalProps;
  // eslint-disable-next-line no-unused-vars
  removeItemFromCart: (cartItemId: string) => void;
  goToCheckout: () => void;
  goBackToCart: () => void;
  submitCheckout: () => void;
  isEditing: boolean;
  cartCount: number;
}

const CartContext = React.createContext({} as CartContextObject);

export default CartContext;

export const useCart = () => {
  const context = React.useContext(CartContext);

  if (context === undefined) {
    throw new Error('useCart cannot be used outside of the CartProvider');
  }
  return context;
};
