import { Button, Frame, Tooltip } from '@kajabi/sage-react';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const usePayButton = () => {
  const { t } = useTranslation();
  const {
    watch,
    getValues,
    formState: { isValid },
  } = useFormContext();
  const isPaymentInProgress = watch('isPaymentInProgress');
  const requirePayment: boolean | undefined = watch('requirePayment');
  const isPaymentDetailComplete: boolean | undefined = watch('isPaymentDetailComplete');
  const serviceAgreementRequired: boolean | undefined = watch('serviceAgreementRequired');
  const serviceAgreementChecked: boolean | undefined = watch('serviceAgreementChecked');
  const useSavedCard: boolean | undefined = watch('useSavedCard');
  const { checkoutSettings, cartAppearance } = getValues();
  const { checkoutButton } = cartAppearance;

  const showDisabledCheckoutTooltip = useMemo(
    () => requirePayment && !checkoutSettings?.paymentMethods?.length,
    [checkoutSettings],
  );

  const siteUserSignedIn = window.Kajabi?.currentSiteUser?.type === 'User';

  // Disable submit if payment is in progress, user is signed in, payment details are incomplete,
  // service agreement is required but not checked, or checkout is disabled
  const shouldDisableSubmit =
    !isValid ||
    isPaymentInProgress ||
    showDisabledCheckoutTooltip ||
    siteUserSignedIn ||
    (!(isPaymentDetailComplete || useSavedCard) && requirePayment) ||
    (serviceAgreementRequired && !serviceAgreementChecked);

  const renderSubmitButtonLabel = () => {
    if (isPaymentInProgress) {
      return t('form.processing');
    }
    if (requirePayment) {
      return t('form.submit_pay_now');
    }
    return t('form.submit_free');
  };

  const checkoutButtonStyle = {
    backgroundColor: shouldDisableSubmit ? '' : checkoutButton.backgroundColor,
    color: shouldDisableSubmit ? '' : checkoutButton.textColor,
    borderColor: shouldDisableSubmit ? '' : checkoutButton.borderColor,
    borderRadius: `${checkoutButton.borderRadius}px`,
  };

  const shouldDisplayPreviewTooltip = shouldDisableSubmit && siteUserSignedIn;

  return {
    shouldDisableSubmit,
    isPaymentInProgress,
    requirePayment,
    renderSubmitButtonLabel,
    checkoutButton,
    checkoutButtonStyle,
    shouldDisplayPreviewTooltip,
    showDisabledCheckoutTooltip,
  };
};

const PayButton = () => {
  const { t } = useTranslation();
  const {
    shouldDisableSubmit,
    isPaymentInProgress,
    renderSubmitButtonLabel,
    checkoutButton,
    checkoutButtonStyle,
    shouldDisplayPreviewTooltip,
    showDisabledCheckoutTooltip,
  } = usePayButton();

  return (
    <Frame direction={Frame.DIRECTIONS.VERTICAL} gap={Frame.GAPS.SM}>
      <Frame align={Frame.ALIGNMENTS.CENTER_CENTER} gap={Frame.GAPS.SM}>
        {shouldDisplayPreviewTooltip && (
          <Tooltip.Element
            className="site-user-tooltip"
            content="Checkout is disabled while you're logged in as a Kajabi site admin"
          />
        )}
        {showDisabledCheckoutTooltip ? (
          <Tooltip.Element
            className="cart-submit-disabled"
            content={t('messages.payments_paused')}
          />
        ) : null}
        <Button
          disabled={shouldDisableSubmit}
          loading={isPaymentInProgress}
          type="submit"
          fullWidth
          style={checkoutButtonStyle}
          data-testid="checkout-submit-button"
        >
          {checkoutButton.text || renderSubmitButtonLabel()}
        </Button>
      </Frame>
    </Frame>
  );
};

export default PayButton;
