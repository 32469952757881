import React from 'react';
import { Frame, useSageBreakpoint, SageClassnames, Divider } from '@kajabi/sage-react';
import { localizedPriceFromMinorUnit } from 'apps/commerce/popup_checkout/util';
import { CartItemImage } from './components/CartItemImage';
import type { CartItem } from './types';

// eslint-disable-next-line no-redeclare
const CheckoutCartItem = ({ cartItem }: { cartItem: CartItem }) => {
  const { totalPrice } = cartItem;
  const breakpoints = useSageBreakpoint();

  const localizedItemPrice = localizedPriceFromMinorUnit(
    totalPrice.currency,
    totalPrice.amount,
    totalPrice.exponent,
  );

  return (
    <>
      <Frame
        key={cartItem.id}
        className="cart-item"
        direction={Frame.DIRECTIONS.HORIZONTAL}
        gap={Frame.GAPS.SM}
        align={Frame.ALIGNMENTS.CENTER_CENTER}
        width={Frame.WIDTHS.FILL}
      >
        <CartItemImage cartItem={cartItem} showQuantity={cartItem.quantityEnabled} />
        <Frame
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.XS}
          align={Frame.ALIGNMENTS.CENTER_SPREAD}
          width={Frame.WIDTHS.FILL}
        >
          <h6 className={SageClassnames.TYPE.HEADING_6}>{cartItem.title}</h6>
          <h5
            className={SageClassnames.TYPE.HEADING_6}
            data-testid="cart-item-price"
            aria-live="polite"
          >
            {localizedItemPrice}
          </h5>
        </Frame>
      </Frame>
      {!breakpoints.sm && <Divider />}
    </>
  );
};

export default CheckoutCartItem;
